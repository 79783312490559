/* eslint-disable react/jsx-pascal-case */
import { jsx, Styled } from 'theme-ui';
/** @jsx jsx */

// TODO: content from markdown?
export default function AboutMe() {
  return (
    <section sx={{
      marginTop: '-2rem',
      borderRadius: 10,
      boxShadow: 'redConsole',
      position: 'relative',
      px: 4,
      paddingTop: '1.2rem',
      paddingBottom: '0.1rem',
      marginBottom: 5,
      '&::after': {
        content: "''",
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: '0 0 50px 13px',
        borderColor: 'transparent transparent #e51515 transparent',
        top: '-50px',
        left: '91%',
        marginLeft: '-10px',
      }
    }}
    >
      <Styled.p>As a repo &quot;for my past self&quot;, in case in the future I can somehow send them back in time to when I need them... or useful to someone else</Styled.p>
    </section>
  )
}