/* eslint-disable react/jsx-pascal-case */
import { Link } from "gatsby"
import { jsx, Styled } from 'theme-ui';

/** @jsx jsx */

export default ({ posts }) => (
  <section>
    <ul sx={{ listStyle: 'none', padding: 1 }}>
      {posts.map(({ node: post }) => (
        <li key={post.id} sx={{ marginBottom: 5, paddingBottom: 1, boxShadow: 'console' }}>
          <Styled.h2
            as={Link}
            to={post.fields.slug}
            sx={{
              backgroundColor: 'secondary',
              textDecoration: 'none',
              fontSize: 3,
              padding: 1,
              color: 'text'
            }}
          >
            {post.frontmatter.title}
          </Styled.h2>
          <Styled.p sx={{ px: 3 }}>{post.excerpt}</Styled.p>
        </li>
      ))}
    </ul>
  </section>
)