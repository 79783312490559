/* eslint-disable react/jsx-pascal-case */
import { graphql } from 'gatsby';
import { jsx, Styled } from 'theme-ui';
import Layout from '../layout';
import PostsList from "../components/PostsList";
import AboutMe from "../components/AboutMe";

/** @jsx jsx */

// markup
const IndexPage = ({ data: { allMdx: { edges: posts } } }) => (
  <Layout>
    <section>
      <div sx={{ borderRadius: '2px', backgroundColor: 'primary', padding: 1, marginBottom: 5 }}>
        <Styled.h1 sx={{ color: 'white', backgroundColor: 'primary', textAlign: 'center' }}>Some notes about web engineering and making things</Styled.h1>
        <Styled.p sx={{ color: 'white', textAlign: 'right', fontFamily: 'monospace' }}>by Pablo Saucedo</Styled.p>
      </div>
      <AboutMe />
      <Styled.h2>Latest posts </Styled.h2>
      <PostsList posts={posts} />
    </section>
  </Layout>
)

export const pageQuery = graphql`
  query postsIndex {
    allMdx(
      filter: {
        frontmatter: {
          template: {eq: "post"},
          draft: { ne: true }
        }
      }
    ) {
      
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default IndexPage
